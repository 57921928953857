<template>
  <main class="main" id="top">
    <div class="container" data-layout="container">
      <Navigation />
      <nav class="mt-3 mb-2" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">주문결제</li>
        </ol>
      </nav>
      <div class="content">
        <div class="d-flex flex-row-reverse p-2 border-bottom">
          <h3 class="mb-2">주문결제</h3>
        </div>
        <div class="d-flex border-bottom p-3 mb-3">
          <div class="avatar avatar-4xl">
            <img class="rounded-soft" :src="imageUrl" alt="" />
          </div>
          <div class="flex-1 ms-3">
            <div class="d-flex flex-column">
              <h4 class="mb-2">{{ product.title }}</h4>
              <p class="fs-0">{{ product.area }}</p>
            </div>
          </div>
        </div>
        <div class="alert alert-danger" role="alert" v-show="isShowAlertMessage">{{ alertMessage }}</div>
        <div class="row">
          <div class="col-lg-8">
            <div class="border-bottom p-3 mb-3">
              <h5 class="mb-3">주문전에 확인하세요.</h5>
              <div class="fs-0">* 예약이후 결제가 완료되어야 최종 신청이 완료됩니다.</div>
              <div class="fs-0">* 결제까지 완료하면 예약현황에서 확인할 수 있습니다.</div>
            </div>
            <div class="border-bottom p-3 mb-3">
              <h5 class="mb-3">구매자 정보</h5>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="booker-name">이름</label>
                <div class="col-sm-10">
                  <input class="form-control" :class="isSoldOutComputed ? 'disabled' : ''" id="booker-name" type="text" ref="booker" placeholder="이름" v-model="booker" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="booker-contact">연락처</label>
                <div class="col-sm-10">
                  <input class="form-control" :class="isSoldOutComputed ? 'disabled' : ''" id="booker-contact" type="tel" ref="contact" placeholder="연락처" v-model="contact" />
                </div>
              </div>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="booker-email">이메일</label>
                <div class="col-sm-10">
                  <input class="form-control" :class="isSoldOutComputed ? 'disabled' : ''" id="booker-email" type="email" ref="email" placeholder="이메일" v-model="email" />
                </div>
              </div>
            </div>
            <div class="border-bottom p-3 mb-3">
              <h5 class="mb-3">{{customInfo}}</h5>
              <textarea style="min-height: 250px" :placeholder="customInfoText" class="form-control" :class="isSoldOutComputed ? 'disabled' : ''" id="uniqueness" v-model="uniqueness" rows="3"></textarea>
            </div>
            <div class="border-bottom p-3 mb-3">
              <h5 class="mb-3">결제수단</h5>
              <div class="form-check mb-0 custom-radio radio-select" :class="product.isBankTransferOnly || isSoldOutComputed ? 'd-none' : ''">

                <input class="form-check-input"  v-model="paymentMethod" id="creditCard" type="radio" value="creditCard" />
                <label class="form-check-label fs-0" for="creditCard">신용카드</label>
              </div>
              <div class="form-check mb-0 custom-radio radio-select">
                <input class="form-check-input" :class="isSoldOutComputed ? 'disabled' : ''" v-model="paymentMethod" id="bankTransfer" type="radio" value="bankTransfer" />
                <label class="form-check-label fs-0" for="bankTransfer">무통장입금</label>
              </div>
            </div>
            <div class="border-bottom p-3 mb-3" v-show="paymentMethod === 'bankTransfer'">
              <h5 class="mb-3">무통장 입금 정보</h5>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="booker-name">예금주</label>
                <div class="col-sm-10">{{ product.accountHolder }}</div>
              </div>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="booker-contact">계좌번호</label>
                <div class="col-sm-10">{{ product.accountNumber }}</div>
              </div>
              <div class="row mb-2">
                <label class="form-label col-sm-2" for="depositor">입금자</label>
                <div class="col-sm-10">
                  <input class="form-control" id="depositor" type="text" ref="depositor" placeholder="입금자명" v-model="depositor" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="ps-lg-2">
              <div class="mb-2">
                <h5>상품정보</h5>
              </div>
              <div class="border-dashed-bottom mb-2"></div>
              <div class="d-flex flex-column mb-2">
                <div class="mb-2 fs-0">{{ product.option1Value }}</div>
                <div class="fs-0">{{ product.option2Value }}</div>
              </div>
              <div class="row justify-content-between mb-3">
                <div class="col">
                  <h5 class="fw-bold d-inline">{{ price.toLocaleString('ko-KR') }}원</h5>
                </div>
                <div class="col-auto">
                  <h5 class="fw-bold d-inline">× {{ quantity }}</h5>
                </div>
              </div>
              <div class="border-dashed-bottom mb-2"></div>
              <div class="row justify-content-between mb-2">
                <div class="col">
                  <p class="fw-bold">총 결제 금액</p>
                </div>
                <div class="col-auto">
                  <h3 class="fw-bold text-primary">{{ payout.toLocaleString('ko-KR') }}원</h3>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button class="btn w-100 btn-primary rounded-pill" :class="isSoldOutComputed ? 'disabled' : ''" type="button" @click="checkout()">결제하기</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterContent />
    </div>
  </main>
</template>
<script>
// @ is an alias to /src
import moment from 'moment';
import Navigation from '@/components/common/Navigation.vue';
import FooterContent from '@/components/common/FooterContent.vue';
import { Bootpay } from '@bootpay/client-js';
import config from '@/config';
export default {
  name: 'ProgramDetail',
  components: {
    Navigation,
    FooterContent,
  },
  data() {
    return {
      user: {},
      programId: -1,
      programProductId: -1,
      price: 0,
      quantity: 0,
      totalQuantity: 0,
      payout: 0,
      isSoldOut: false,
      reservedQuantity: 0,
      paymentMethod: 'creditCard',
      booker: '',
      contact: '',
      email: '',
      customInfo: '특이사항',
      customInfoText: '',
      uniqueness: '',
      depositor: '',
      buyToggle: true,
      product: {
        price: 0,
        quantity: 0,
        reservedQuantity: 0,
        accountHolder: '',
        accountNumber: '',
        isSoldOut: 1,
        programId: -1,
        extraPrice: 0,
        area: '',
        option2Value: '',
        option1Value: '',
        title: '',
        id: 23,
        isBankTransferOnly: false
      },
      tempData: {},
      isShowAlertMessage: false,
      alertMessage: '',
    };
  },
  created() {
    this.programId = this.$route.params.programId;
    this.customInfoText = this.$route.params.customInfo;
    this.programProductId = this.$route.params.programProductId;
    this.quantity = this.$route.params.quantity;


    this.user = this.$func.getUser();
    this.booker = this.user.name;
    this.contact = this.user.contact;
    this.email = this.user.email;
    this.depositor = this.user.name;
    if (this.programId && this.programProductId && this.quantity) {
      const orderParams = { programId: this.programId, programProductId: this.programProductId, quantity: this.quantity };
      localStorage.setItem('orderParams', JSON.stringify(orderParams));
    } else {
      const orderParams = JSON.parse(localStorage.getItem('orderParams'));
      //console.log('restore orderParams:', orderParams);
      if (orderParams) {
        this.programId = orderParams.programId;
        this.programProductId = orderParams.programProductId;
        this.quantity = orderParams.quantity;
      } else {
        this.$toast.warning('구매내역이 없습니다.');
        this.$router.push('/');
      }
    }
    this.getProductForOrder();
  },
  computed: {
    isSoldOutComputed() {
      return this.isSoldOut || 0 > parseInt((this.totalQuantity - this.product.reservedQuantity) - this.quantity);
    },
    imageUrl() {
      return process.env.VUE_APP_BASE_URL + this.product.imagePath;
    },
  },
  methods: {
    getProductForOrder() {
      this.$http
        .get(`/experience/${this.programId}`)
        .then((response) => {
          // console.log('getProductForOrder-response:', response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.product = response.data;
          this.price = this.product.price;
          this.payout = this.price * this.quantity;

          this.product.products.forEach((item) => {
            if (`${item.id}` === `${this.programProductId}`) {
              this.totalQuantity = item.quantity;
              this.reservedQuantity = item.reservedQuantity;
              this.isSoldOut = item.isSoldOut;
              this.product.option1Value = item.option1Value.value;
              if(item.option2Value != null){
                this.product.option2Value = item.option2Value.value;
              }
              this.price = this.price + item.extraPrice;
              this.payout = this.price  * this.quantity;
            }
          });

          if (this.isSoldOut) {
            this.isShowAlertMessage = true;
            this.alertMessage = '선택하신 상품이 품절 또는 원하시는 수량보다 부족합니다. ';
          }
        })
        .catch((error) => {
          console.log(error);

        });
    },

    validate() {
      if (this.booker.length < 2 || this.booker.length > 10) {
        console.debug('booker.length', this.booker.length);
        this.$toast.warning('구매자 이름은 2~10자로 입력하세요.');
        this.$refs.booker.focus();
        return false;
      }

      if (!this.contact) {
        this.$toast.warning('연락처를 입력해주세요.');
        this.$refs.contact.focus();
        return false;
      }

      if (this.contact && !this.$func.validatePhoneNumber(this.contact)) {
        this.$toast.warning('연락처가 올바르지 않습니다.');
        this.$refs.contact.focus();
        return false;
      }

      if (this.email && !this.$func.validateEmail(this.email)) {
        this.$toast.warning('이메일이 올바르지 않습니다.');
        this.$refs.email.focus();
        return false;
      }

      if (this.paymentMethod == 'bankTransfer' && (this.depositor.length < 2 || this.depositor.length > 10)) {
        this.$toast.warning('입금자 이름은 2~10자로 입력하세요.');
        this.$refs.depositor.focus();
        return false;
      }

      // const endDate = moment(this.product.END_DATE); //SS_PROGRAM_PRODUCT.END_DATE 상품종료일자
      // const nowDate = moment();
      // const diff = parseInt(moment.duration(endDate.diff(nowDate)).asDays());
      // //상품종료일이 지나면 취소 불가
      // if (diff < -1) {
      //   this.$toast.warning('종료된 오감체험입니다.');
      //   return false;
      // }
      return true;
    },
    async miscLog(message) {

      // let sendMessage = `WEB-[${this.user.name}-${this.user.email}] - ${message}`;
      // const api = await this.$http.post('/misc/log', {message: sendMessage});

      // console.log(api);
    },
    payByCreditCard(reservationId) {
      try {

        let option1Value = null;
        let option2Value = null;
        this.product.products.forEach((item) => {
          if (item.id === this.programProductId) {
            option1Value = item.option1Value.value
            if(item.option2Value !== null){
              option2Value = item.option2Value.value
            }
          }
        });


        let orderName = this.product.title;
        if (option1Value != null) {
          orderName = orderName + "(옵션1: " + option1Value + ")";
        }
        if (option2Value != null) {
          orderName = orderName + " (옵션2: "  + option2Value + ")";
        }
        //payload.extra.separately_confirmed = true; 일 경우 승인 전 해당 이벤트가 호출됨
        this.miscLog('부트페이 카드 결제 시작');
        Bootpay.requestPayment({
          application_id: process.env.VUE_APP_BOOTPAY_KEY, // config.bootPayJavascriptKey,
          price: this.payout,
          // price: 1000,
          order_name: orderName,
          order_id: this.$func.getUUID(),
          // order_id: 'TEST_ORDER_ID',
          pg: '페이앱',
          method: '카드',
          tax_free: 0,
          user: {
            id: this.user.uid,
            username: this.user.name,
            phone: this.user.contact,
            email: this.user.email,
          },
          items: [
            {
              id: this.programProductId,
              name: orderName,
              qty: this.quantity,
              // qty: 1,
              price: this.price,
              // price: 1000,
            },
          ],
          extra: {
            open_type: 'popup', //redirect, iframe, popup
            card_quota: '0,2,3',
            escrow: false,
          },
        }).then(async (response) => {
          await this.miscLog(`부트페이 카드 결제 완료 ${JSON.stringify(response)}`);
          console.log('response:', response);
          const receiptDetail = response.data;
          await this.confirmReservation(reservationId, receiptDetail)
          // this.saveReservation(
          //     2,
          //     moment(receiptDetail.purchased_at).format('YYYY-MM-DD HH:mm:ss'),
          //     receiptDetail.receipt_id,
          //     JSON.stringify(receiptDetail)
          // );
        }).catch((e) => {
          this.miscLog(`Bootpay.requestPayment 함수 실행에서의 부트페이 카드 결제 에러발생 ${JSON.stringify(e)}`);
          this.$toast.error(`관리자에게 문의해주세요.\n ${JSON.stringify(e)}`);
          this.$router.back();
        });


      } catch (e) {
        this.miscLog(`payByCreditCard 함수자체 부트페이 카드 결제 에러발생 ${JSON.stringify(e)}`);
        // 결제 진행중 오류 발생
        // e.error_code - 부트페이 오류 코드
        // e.pg_error_code - PG 오류 코드
        // e.message - 오류 내용
        //console.log(e.message);
        switch (e.event) {
          case 'cancel':
            // 사용자가 결제창을 닫을때 호출
            //console.log(e.message);
            break;
          case 'error':
            // 결제 승인 중 오류 발생시 호출
            this.$toast.error(`관리자에게 문의해주세요.\n ${JSON.stringify(e)}`);
            this.$router.back();
            break;
        }
      }
    },

    checkout() {
      if (this.validate()) {
        if(!this.buyToggle){
          this.$toast.warning("결제가 진행 중입니다.\n계속 진행 중일시 새로고침 해주세요.");
          return
        }
        if(this.buyToggle){
          this.buyToggle = false;

          if (this.payout === 0) {
            this.saveReservation(2, moment().format('YYYY-MM-DD HH:mm:ss'), '', '');
          } else {
            if (this.paymentMethod === 'creditCard') {
              this.prepareReservation();
            } else {
              this.saveReservation(1, moment().format('YYYY-MM-DD HH:mm:ss'), '', '');
            }
          }
        }

      }
    },

    async prepareReservation(){
      try{
        this.miscLog(`prepareReservation /mobile/reservation/prepare api 함수 실행`);
        //console.log('saveReservation - status:', status, ',paymentTime:', paymentTime, ',receiptId:', receiptId, ',receiptDetail:', receiptDetail);
        let userReservation = {};
        // userReservation.userUid = this.user.uid;
        userReservation.programId = parseInt(this.programId);
        userReservation.programProductId = parseInt(this.programProductId);
        userReservation.price = parseInt(this.price);
        // userReservation.price = 1000;
        userReservation.quantity = parseInt(this.quantity);
        userReservation.payout = parseInt(this.payout);

        userReservation.booker = this.booker;
        userReservation.contact = this.contact;
        userReservation.email = this.email;
        userReservation.uniqueness = this.uniqueness;
        const res = await this.$http.post('/reservation/prepare', userReservation);
        await this.miscLog(`prepareReservation /mobile/reservation/prepare api 함수 실행 ${JSON.stringify(res)}`);
        if(res.data.id){
          this.miscLog(`prepareReservation /mobile/reservation/prepare api 함수 성공`);
          this.payByCreditCard(res.data.id);
        } else {
          await this.miscLog(`prepareReservation /mobile/reservation/prepare api 함수 에러`);
          console.log(res);
        }
      }catch (e) {
        await this.miscLog(`prepareReservation /mobile/reservation/prepare api 함수 에러 ${JSON.stringify(e)}`);
        this.$toast.error(`관리자에게 문의해주세요.\n ${e['statusCode']} - ${e['message']}`);
        this.$router.back();
        console.log(e);
      }

    },

    async confirmReservation(reservationId, paymentResponse){
      try{
        await this.miscLog(`confirmReservation /mobile/reservation/confirm api 함수 실행`);
        const res = await this.$http.post('/reservation/confirm', {
          payout: parseInt(this.payout),
          id: reservationId,
          paymentResponse: JSON.stringify(paymentResponse)
        });
        await this.miscLog(`confirmReservation /mobile/reservation/confirm api 함수 종료`);
        if(res.status === 200 || res.status === 201){
          this.miscLog(`saveReservation /mobile/reservation api 성공 ${JSON.stringify(res)}`);

          this.$toast.success("결제가 완료되었습니다.");

          this.$router.push('/reservations');
        } else {
          this.miscLog(`saveReservation /mobile/reservation api 실패 ${JSON.stringify(res)}`);
          console.log(res);
          this.$router.back();
          this.$toast.success(`관리자에게 문의해주세요.\n${JSON.stringify(res)}`);
        }
      }catch (e) {
        this.miscLog(`saveReservation /mobile/reservation/confirm api ERROR ${JSON.stringify(e)}`);
        console.log(e);
        this.$toast.error(`관리자에게 문의해주세요.\n ${e['statusCode']} - ${e['message']}`);
        this.$router.back();
      }


    },

    saveReservation(status, paymentTime, receiptId, receiptDetail) {
      this.miscLog(`saveReservation /mobile/reservation api 함수 실행`);
      this.miscLog(`saveReservation /mobile/reservation api ${status === 1 ? '무통장' : '카드'}`);
      //console.log('saveReservation - status:', status, ',paymentTime:', paymentTime, ',receiptId:', receiptId, ',receiptDetail:', receiptDetail);
      let userReservation = {};
      // userReservation.userUid = this.user.uid;
      userReservation.programId = parseInt(this.programId);
      userReservation.programProductId = parseInt(this.programProductId);
      userReservation.price = parseInt(this.price);
      // userReservation.price = 1000;
      userReservation.quantity = parseInt(this.quantity);
      // userReservation.quantity = 1;
      userReservation.payout = parseInt(this.payout);
      userReservation.paymentMethod = this.paymentMethod == 'creditCard' ? 1 : 2;
      userReservation.status = status; //1:대기, 2:예약완료, 3:예약취소요청, 4:취소완료
      // userReservation.paymentTime = paymentTime;
      // userReservation.uniqueness = this.customInfo;

      // userReservation.receiptId = receiptId;
      if(receiptDetail !== ""){
        userReservation.paymentResponse = receiptDetail;
      }

      userReservation.booker = this.booker;
      userReservation.contact = this.contact;
      userReservation.email = this.email;
      userReservation.uniqueness = this.uniqueness;
      userReservation.depositor = this.depositor;
      // userReservation.isInsurance = false;
      //console.log('userReservation:', userReservation);
      this.$http
        .post('/reservation', userReservation)
        .then((response) => {
          //console.log(response);
          if(response.status === 200 || response.status === 201){
            this.miscLog(`saveReservation /mobile/reservation api 성공 ${JSON.stringify(response)}`);

            this.$toast.success("결제가 완료되었습니다.");

            this.$router.push('/reservations');
          } else {
            this.miscLog(`saveReservation /mobile/reservation api 실패 ${JSON.stringify(response)}`);
            console.log(response);
            this.$toast.error(`관리자에게 문의해주세요.\n${JSON.stringify(response)}`);
            this.$router.back();
          }
        })
        .catch((error) => {
          this.miscLog(`saveReservation /mobile/reservation api ERROR ${JSON.stringify(error)}`);
          console.log(error);
          this.$toast.error(`관리자에게 문의해주세요.\n ${error['statusCode']} - ${error['message']}`);
          this.$router.back();
        });
    },
  },
};
</script>
